var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"value":_vm.nav,"mini-variant":_vm.localMini,"app":"","color":"new_color"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"margin-left":"25px"}},[_vm._v(_vm._s(_vm.$store.state.user.name))])],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({staticStyle:{"margin":"0px"}},'v-list-item-icon',attrs,false),on),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.status_connection === 'connected'),expression:"$store.state.status_connection === 'connected'"}],staticClass:"status"},[_c('v-badge',{attrs:{"bordered":"","left":"","color":"#29cc90","dot":"","offset-x":"-5","offset-y":"0"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.status_connection === 'disconnected'),expression:"$store.state.status_connection === 'disconnected'"}],staticClass:"status"},[_c('v-badge',{attrs:{"bordered":"","left":"","color":"#992630","dot":"","offset-x":"-5","offset-y":"0"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.status_connection === 'connecting'),expression:"$store.state.status_connection === 'connecting'"}],staticClass:"status"},[_c('v-badge',{attrs:{"bordered":"","left":"","color":"blue","dot":"","offset-x":"-5","offset-y":"0"}})],1)])]}}])},[_c('span',[_vm._v(" Status conexão: "+_vm._s(_vm.statusText)+" ")])])],1),_c('v-list-item',{staticStyle:{"padding":"8px"}},[_c('v-list-item-content',{staticStyle:{"font-size":"13px"}},[_vm._v(" v "+_vm._s(_vm.$store.state.version)+" ")])],1),_c('v-list-item',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.doLogout}},'v-icon',attrs,false),on),[_vm._v(" mdi-logout-variant ")])]}}])},[_c('span',[_vm._v("Sair")])])],1)]},proxy:true}])},[_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c('router-link',{key:item.title,class:{ 'disabled': item.disabled },attrs:{"to":item.route}},[_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],key:item.title,class:{ 'item-disabled': item.disabled }},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-icon',_vm._g(_vm._b({},'v-list-item-icon',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1),(item.disabled)?_c('v-list-item-action',{staticClass:"block"},[_c('v-icon',[_vm._v("mdi-lock")])],1):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }